import * as React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  if (typeof window !== `undefined`) {
    navigate('/online-news/')
  }

  return(
    <Layout>
      <Seo title="Home" />
    </Layout>
  )
}

export default IndexPage